import { PartialTheme } from "@fluentui/react";

export const defaultTheme: PartialTheme = {
    defaultFontStyle: { fontFamily: "Montserrat", fontWeight: "regular" },
    palette: {
        themePrimary: "#48b593",
        themeLighterAlt: "#f6fcfa",
        themeLighter: "#dcf3ec",
        themeLight: "#bfe9dc",
        themeTertiary: "#87d3bb",
        themeSecondary: "#5abe9e",
        themeDarkAlt: "#41a384",
        themeDark: "#378a6f",
        themeDarker: "#296552",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#595959",
        neutralSecondary: "#373737",
        neutralPrimaryAlt: "#2f2f2f",
        neutralPrimary: "#000000",
        neutralDark: "#151515",
        black: "#0b0b0b",
        white: "#ffffff",
    },
    components: {
        TextField: {
            styles: {
                root: {
                    ".ms-TextField-field:disabled": {
                        "-webkit-text-fill-color": "#595959",
                        opacity: 1,
                    },
                    ".ms-TextField-field:placeholder": {
                        "-webkit-text-fill-color": "#595959",
                        opacity: 1,
                    },
                },
            },
        },
    },
};
