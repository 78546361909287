import * as React from "react";
import { observer } from "mobx-react";
import { AuthStore } from "../auth/AuthStore";
import { AppMenuStore } from "../app/appHeader/appMenu/AppMenuStore";
import { TypesService } from "../types/TypesService";
import { TypesStore } from "../types/TypesStore";
import { ThemeStore } from "../theme";
import { ThemeContext } from "../Root";
import { ThemeProvider } from "@fluentui/react";

export interface IMainContext {
    auth: AuthStore;
    menu: AppMenuStore;
    theme: ThemeStore;

    typesStore: TypesStore;
    typesService: TypesService;
}

export const WithThemeProvider = observer(
    ({
        children,
        className,
        style = { display: "contents" },
    }: React.PropsWithChildren<{ className?: string; style?: React.CSSProperties }>) => {
        const themeContext = React.useContext(ThemeContext);

        return (
            <ThemeProvider theme={themeContext.theme} applyTo="body" style={style} className={className}>
                {children}
            </ThemeProvider>
        );
    }
);
