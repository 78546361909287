import * as React from "react";
import * as ReactDOM from "react-dom";
import "./index.css";
import { Root } from "./Root";
import { initializeIcons } from "@fluentui/react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";

initializeFileTypeIcons();
initializeIcons();
const rootElement = document.getElementById("root");
ReactDOM.render(React.createElement(Root, { apiURL: rootElement?.getAttribute("data-api-url") ?? "" }), rootElement);
