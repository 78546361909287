import * as React from "react";
import * as ReactDOM from "react-dom";
import { mergeStyleSets, Spinner, SpinnerSize, Theme } from "@fluentui/react";
import { ThemeContext } from "../Root";
import { IntlShape } from "react-intl";
import { WithThemeProvider } from "./WithThemeProvider";

const busyIndicatorOverlayClassNames = (theme: Theme) =>
    mergeStyleSets({
        busyIndicatorOverlay: {
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.whiteTranslucent40,
            zIndex: 1000002,
        },
    });

const BusyIndicatorOverlay = ({ intl }: { intl: IntlShape }) => {
    const { theme } = React.useContext(ThemeContext);
    const classNames = busyIndicatorOverlayClassNames(theme);

    return (
        <WithThemeProvider className={classNames.busyIndicatorOverlay} style={{}}>
            <div role="status" aria-label={intl.formatMessage({ id: "app.status.loadingInProgress" })} />
            <Spinner size={SpinnerSize.large} />
        </WithThemeProvider>
    );
};

export class BusyService {
    public static busy<T>(promise: Promise<T>, intl: IntlShape): Promise<T> {
        this.show(intl);
        promise.finally(() => this.hide());
        return promise;
    }

    private static busyCount = 0;
    private static busyIndicator = false;

    private static show(intl: IntlShape) {
        this.busyCount++;
        setTimeout(() => {
            if (this.busyCount > 0 && !this.busyIndicator) {
                this.busyIndicator = true;
                const container = document.getElementById("busy");
                if (container) {
                    ReactDOM.render(<BusyIndicatorOverlay intl={intl} />, container);
                }
            }
        }, 0);
    }

    private static hide() {
        this.busyCount--;
        setTimeout(() => {
            if (this.busyCount === 0 && this.busyIndicator) {
                this.busyIndicator = false;
                const container = document.getElementById("busy");
                if (container) {
                    ReactDOM.unmountComponentAtNode(container);
                }
            }
        }, 0);
    }
}
