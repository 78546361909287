import { computed, makeObservable, observable } from "mobx";
import { createTheme, Theme, loadTheme, IPalette } from "@fluentui/react";
import { defaultTheme } from "./DefaultTheme";

export class ThemeStore {
    constructor() {
        makeObservable(this);
    }

    @observable theme: Theme = createTheme(defaultTheme);

    @computed get palette(): IPalette {
        return this.theme.palette;
    }

    // dev-only
    setThemeFromString = (value?: string): void => {
        if (!value) {
            return;
        }
        try {
            this.theme = createTheme({ palette: JSON.parse(value) });
            loadTheme(this.theme);
        } catch (e) {
            // eslint-disable-next-line no-alert
            alert("This is not a valid JSON.");
        }
    };

    createTheme(theme: Theme): void {
        this.theme = theme;
    }
}
