import { IEqualsExpr } from "../db/Database";
import {
    IEntityContent,
    IEntityLocalizationContent,
    IEntityLocalizationWrapper,
    IEntityWrapper,
} from "../entities/EntitiesModel";
import { ILocalized } from "../types/TypesModel";
import { UnauthorizedError } from "../util/Error";

export const BuiltInUserId = "00000000-0000-0000-0000-000000000000";

export interface IAuthorizationDescriptor {
    name: string;
    label?: ILocalized<string>;
    objects: {
        name: string;
        label?: ILocalized<string>;
    }[];
    verbs: {
        name: string;
        label?: ILocalized<string>;
    }[];
}

export interface IAuthUserProfile {
    id: string;
    email: string;
    name: string;
    public: boolean;
    picture?: {
        id: string;
        mime: string;
        width: number;
        height: number;
    };
}
export const WildcardObject = "*";
export const WildcardVerb = "*";

export const VerbCreate = "create";
export const VerbRead = "read";
export const VerbUpdate = "update";
export const VerbDelete = "delete";
export const VerbReadHistory = "readHistory";

export const ObjectTypePrefix = "type/";
export const ObjectTypeWildcard = `${ObjectTypePrefix}*`;

export const ObjectEntityPrefix = "entity/";
export const ObjectEntityWildcard = `${ObjectEntityPrefix}*`;

export const ObjectEntitiyLocalizationPrefix = "entityLocalization/";
export const ObjectEntitiyLocalizationWildcard = `${ObjectEntitiyLocalizationPrefix}*`;

export const ObjectLocalesPrefix = "locales/";
export const ObjectLocalesWildcard = `${ObjectLocalesPrefix}*`;

export class RuleEvaluator {
    private readonly rules: INormalizedRule[];

    constructor(rules: IRule[]) {
        this.rules = normalizeRules(rules);
    }

    public allowsEntity<L extends IEntityContent>(verb: string, type: string, object?: IEntityWrapper<L>): boolean {
        const objectCode = `${ObjectEntityPrefix}${type}`;
        return this.matches(verb, (granted) => granted === ObjectEntityWildcard || granted === objectCode);
    }

    public allowsEntityOrThrow<L extends IEntityContent>(verb: string, type: string, object?: IEntityWrapper<L>) {
        if (!this.allowsEntity(verb, type, object)) {
            throw new UnauthorizedError();
        }
    }

    public allowEntityTranslation<L extends IEntityLocalizationContent>(
        verb: string,
        type: string,
        object?: IEntityLocalizationWrapper<L>
    ): boolean {
        const objectCode = `${ObjectEntitiyLocalizationPrefix}${type}`;
        return this.matches(verb, (granted) => granted === ObjectEntitiyLocalizationWildcard || granted === objectCode);
    }

    public allowEntityTranslationOrThrow<L extends IEntityLocalizationContent>(
        verb: string,
        type: string,
        object?: IEntityLocalizationWrapper<L>
    ) {
        if (!this.allowEntityTranslation(verb, type, object)) {
            throw new UnauthorizedError();
        }
    }

    public allowsType(verb: string, type: string): boolean {
        const objectCode = `${ObjectTypePrefix}${type}`;
        return this.matches(verb, (granted) => {
            return granted === ObjectTypeWildcard || granted === objectCode;
        });
    }

    public getAllowedTypes(verb: string): false | true | string[] {
        const types: string[] = [];
        for (const r of this.rules) {
            if (r.verbs.indexOf(verb) !== -1 || r.verbs.indexOf(WildcardVerb) !== -1) {
                for (const granted of r.objects) {
                    if (granted.object === ObjectTypeWildcard || granted.object === WildcardObject) {
                        return true;
                    }
                    if (granted.object.startsWith(ObjectTypePrefix)) {
                        types.push(granted.object.substr(ObjectTypePrefix.length));
                    }
                }
            }
        }
        return types.length > 0 ? types : false;
    }

    public allowsTypeOrThrow(verb: string, type: string) {
        if (!this.allowsType(verb, type)) {
            throw new UnauthorizedError();
        }
    }

    public allowsLocale(verb: string): boolean {
        switch (verb) {
            case VerbRead:
                return this.matches(verb, (granted) => {
                    return granted === ObjectLocalesWildcard && [VerbRead, VerbUpdate].indexOf(verb) > -1;
                });
            case VerbUpdate:
                return this.matches(verb, (granted) => {
                    return granted === ObjectLocalesWildcard && verb === VerbUpdate;
                });
            default:
                return false;
        }
    }

    public allowsLocaleOrThrow(verb: string) {
        if (!this.allowsLocale(verb)) {
            throw new UnauthorizedError();
        }
    }

    private matches(verb: string, matcher: (granted: string, condition?: ObjectCondition) => boolean): boolean {
        for (const r of this.rules) {
            for (const v of r.verbs) {
                if (v === verb || v === WildcardVerb) {
                    for (const granted of r.objects) {
                        if (
                            (granted.object === WildcardObject && granted.condition === undefined) ||
                            matcher(granted.object, granted.condition)
                        ) {
                            return true;
                        }
                    }
                    break;
                }
            }
        }
        return false;
    }
}

export function normalizeRules(rules: IRule[]): INormalizedRule[] {
    return rules.map((r) => ({
        ...r,
        objects: r.objects.map((o) => {
            if (typeof o === "string") {
                return {
                    object: o,
                };
            }
            return o;
        }),
    }));
}

export type ObjectCondition = IEqualsExpr;

export interface IRuleObject {
    object: string;
    condition?: ObjectCondition;
}

export interface IRule {
    objects: (IRuleObject | string)[];
    verbs: string[];
}

export interface INormalizedRule {
    objects: IRuleObject[];
    verbs: string[];
}
