import { Language } from "./i18nMessages";
import { bootstrapMessages as msgDe } from "../localizations/bootstrap-messages-de";
import { bootstrapMessages as msgEn } from "../localizations/bootstrap-messages-en";

export function loadBootstrapMessages(locale: string) {
    switch (locale) {
        case Language.DE:
            return msgDe;
        default:
        case Language.EN:
            return msgEn;
    }
}
