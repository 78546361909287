import { projectBootstrapMessages } from "./project-bootstrap-messages-de";

export const bootstrapMessages: Record<string, string> = {
    "login.title": "Anmelden",
    "login.email": "E-Mail",
    "login.password": "Passwort",
    "login.login": "Anmelden",
    "login.invalid-credentials": "E-Mail oder Password ungültig",

    "operation.load.description": "{entity} laden",
    "operation.save.description": "{entity} speichern",
    "operation.delete.description": "{entity} löschen",
    "operation.delete.description.load": "{entity} zum Löschen laden",
    "operation.error.description": "{description} fehlgeschlagen",
    "operation.error.description.login.label": "Anmelden",
    "operation.error.description.loadApplication.label": "Application laden",
    "operation.error.description.loadTypes.label": "Typen laden",
    "operation.error.unknown": "Unbekannter Fehler",
    "operation.error.NotFound":
        "Das Objekt {entity} existiert nicht auf dem Server. Es könnte durch einen anderen Benutzer gelöscht worden sein.",
    "operation.error.AlreadyExists":
        "Das Objekt {entity} existiert bereits auf dem Server. Es könnte durch einen anderen Benutzer angelegt worden sein.",
    "operation.error.ConcurrentModification":
        "Das Objekt {entity} wurde auf dem Server bereits modifiziert. Es könnte durch einen anderen Benutzer bearbeitet worden sein.",
    "operation.error.Unauthorized": "Ihre Anmeldesitzung ist abgelaufen.",
    "operation.outcome.cancel": "Abbrechen",
    "operation.outcome.retry": "Wiederholen",
    "operation.outcome.reload": "Neu Laden",

    "app.status.loadingInProgress": "Laden...",
    ...projectBootstrapMessages,
};
