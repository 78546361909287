import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { AuthService, IAuthInfo } from "./AuthService";
import {
    IAuthorizationDescriptor,
    IRule,
    IAuthUserProfile,
    ObjectTypePrefix,
    RuleEvaluator,
    BuiltInUserId,
    VerbCreate,
    VerbDelete,
    VerbRead,
    VerbReadHistory,
    VerbUpdate,
    WildcardObject,
    WildcardVerb,
    INormalizedRule,
} from "./AuthModel";
import { NotFoundError } from "../util/Error";
import { IEntityContent, IEntityWrapper } from "../entities/EntitiesModel";

export interface IAuthUserProfileWithPictureURL extends IAuthUserProfile {
    pictureURL?: string;
}

export interface IAuthTokenStore {
    read: () => string | undefined;
    write: (token: string | undefined) => void;
}

export class AuthStore {
    @observable
    private _token?: string;
    @observable
    public userId?: string;
    @observable
    public authorizationRules?: INormalizedRule[];
    @observable
    public ruleEvaluator?: RuleEvaluator;
    @observable
    public user?: IAuthUserProfile;
    @observable
    public userAddressRef?: IEntityWrapper<IEntityContent>;
    @observable
    public sellers?: IEntityWrapper<IEntityContent>[];
    @observable
    public systemUsers?: IEntityWrapper<IEntityContent>[];

    constructor(private authService: AuthService, private tokenStore?: IAuthTokenStore) {
        makeObservable(this);
        this.token = this.tokenStore?.read();
    }

    public set token(v: string | undefined) {
        this._token = v;
        this.tokenStore?.write(v);
    }

    @computed
    public get token(): string | undefined {
        return this._token;
    }

    @computed
    public get loggedIn(): boolean {
        return this.token !== undefined;
    }

    @action
    public async login(login: string, password: string): Promise<boolean> {
        const authInfo = await this.authService.login(login, password);
        if (authInfo) {
            await this.updateAuthAndUserInfo(authInfo);
            return true;
        }
        await this.updateAuthAndUserInfo();
        return false;
    }

    @action
    public async loginSharedKey(sharedKey: string): Promise<boolean> {
        const authInfo = await this.authService.loginSharedKey(sharedKey);
        if (authInfo) {
            await this.updateAuthAndUserInfo(authInfo);
            return true;
        }
        await this.updateAuthAndUserInfo();
        return false;
    }

    @action
    public setUserAddressRef(userAddressRef?: IEntityWrapper<IEntityContent>): void {
        this.userAddressRef = userAddressRef;
    }

    @action
    public setSellers(sellers?: IEntityWrapper<IEntityContent>[]): void {
        this.sellers = sellers;
    }

    @action
    public setSystemUsers(systemUsers?: IEntityWrapper<IEntityContent>[]): void {
        this.systemUsers = systemUsers;
    }

    @action
    public async logout(): Promise<void> {
        if (this.token !== undefined) {
            await this.authService.logout(this.token);
            await this.updateAuthAndUserInfo();
        }
    }

    @action
    public async check(): Promise<boolean> {
        if (this.token !== undefined) {
            const authInfo = await this.authService.check(this.token);
            if (authInfo) {
                await this.updateAuthAndUserInfo(authInfo);
                return true;
            }
        }
        await this.updateAuthAndUserInfo();
        return false;
    }

    public getAuthorizationDescriptors(): Promise<IAuthorizationDescriptor[]> {
        return this.authService.getAuthorizationDescriptors();
    }

    @action
    private async updateAuthAndUserInfo(authInfo?: IAuthInfo) {
        let user: IAuthUserProfile | undefined;
        try {
            if (authInfo) {
                user = await this.authService.getProfile();
            }
        } catch (e) {
            if (!(e instanceof NotFoundError)) {
                throw e;
            }
        }

        runInAction(() => {
            if (authInfo) {
                this.token = authInfo.token;
                this.userId = authInfo.userId;
                this.authorizationRules = authInfo.rules;
                this.ruleEvaluator = new RuleEvaluator(this.authorizationRules);
                this.user = user;
            } else {
                this.token = undefined;
                this.userId = undefined;
                this.authorizationRules = undefined;
                this.ruleEvaluator = undefined;
                this.user = undefined;
            }
        });
    }

    @action
    public async refreshUserData(): Promise<void> {
        this.user = await this.authService.getProfile();
    }

    public async setUserPassword(currentPassword: string, newPassword: string): Promise<boolean> {
        const resp = await this.authService.setUserPassword(currentPassword, newPassword, this.token);
        if (resp) {
            return true;
        }
        return false;
    }

    public async setUserProfilePicture(file?: File): Promise<void> {
        await this.authService.setUserProfilePicture(file, this.token);
        await this.refreshUserData();
    }

    @computed
    public get userInitials(): string | undefined {
        return this.userName
            ? this.userName
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .substring(0, 2)
                  .toUpperCase()
            : undefined;
    }

    @computed
    public get userPreview(): string | undefined {
        return this.authService.getProfilePictureURL(this.user);
    }

    @computed
    public get userName(): string | undefined {
        return this.user?.name;
    }

    @computed
    public get userEmail(): string | undefined {
        return this.user?.email;
    }

    @computed
    public get isAdministrator(): boolean {
        if (!this.authorizationRules) {
            return false;
        }
        return !!this.authorizationRules.find(
            (r) =>
                r.objects.find((o) => o.object === WildcardObject || o.object.startsWith(ObjectTypePrefix)) &&
                r.verbs.find((v) => v === WildcardVerb || v === VerbCreate || v === VerbUpdate || v === VerbDelete)
        );
    }

    @computed
    public get canReadLocales(): boolean {
        return this.ruleEvaluator?.allowsLocale(VerbRead) || false;
    }

    @computed
    public get canUpdateLocales(): boolean {
        return this.ruleEvaluator?.allowsLocale(VerbUpdate) || false;
    }

    @computed
    public get canCreateTypes(): boolean {
        return this.ruleEvaluator?.allowsType(VerbCreate, "") || false;
    }

    @computed
    public get canReadTypes(): boolean {
        return this.ruleEvaluator?.allowsType(VerbRead, WildcardObject) || false;
    }

    public canReadType(type: string): boolean {
        return this.ruleEvaluator?.allowsType(VerbRead, type) || false;
    }

    public canEditType(type: string): boolean {
        return this.ruleEvaluator?.allowsType(VerbUpdate, type) || false;
    }

    public canDeleteType(type: string): boolean {
        return this.ruleEvaluator?.allowsType(VerbDelete, type) || false;
    }

    public canReadEntity(type: string): boolean {
        return this.ruleEvaluator?.allowsEntity(VerbRead, type) || false;
    }

    public canCreateEntity(type: string): boolean {
        return this.ruleEvaluator?.allowsEntity(VerbCreate, type) || false;
    }

    public canEditEntity(type: string): boolean {
        return this.ruleEvaluator?.allowsEntity(VerbUpdate, type) || false;
    }

    public canDeleteEntity(type: string): boolean {
        return this.ruleEvaluator?.allowsEntity(VerbDelete, type) || false;
    }

    public canReadHistoryEntity(type: string): boolean {
        return this.ruleEvaluator?.allowsEntity(VerbReadHistory, type) || false;
    }

    public canCreateEntityTranslations(type: string): boolean {
        return this.ruleEvaluator?.allowEntityTranslation(VerbCreate, type) || false;
    }

    public canReadEntityTranslations(type: string): boolean {
        return this.ruleEvaluator?.allowEntityTranslation(VerbRead, type) || false;
    }

    public canEditEntityTranslations(type: string): boolean {
        return this.ruleEvaluator?.allowEntityTranslation(VerbUpdate, type) || false;
    }

    public canDeleteEntityTranslations(type: string): boolean {
        return this.ruleEvaluator?.allowEntityTranslation(VerbDelete, type) || false;
    }

    @computed
    public get isSystemUser(): boolean {
        return this.userId === BuiltInUserId;
    }

    public async getUserProfiles(userIds: string[]): Promise<(IAuthUserProfileWithPictureURL | undefined)[]> {
        return Promise.all(
            userIds.map((uId) =>
                this.authService.getProfileByUserId(uId).then(
                    (profile) => {
                        if (profile.picture) {
                            return {
                                ...profile,
                                pictureURL: this.authService.getProfilePictureURLByUserId(profile),
                            };
                        }
                        return profile;
                    },
                    () => undefined
                )
            )
        );
    }

    public async queryUserProfiles(query: string): Promise<IAuthUserProfile[]> {
        return this.authService.queryUserProfiles(query);
    }

    public async queryUserProfilesWithPictureUrl(
        query: string
    ): Promise<(IAuthUserProfileWithPictureURL | undefined)[]> {
        const users = await this.authService.queryUserProfiles(query);
        return Promise.all(
            users.map((profile) => {
                if (profile.picture) {
                    return {
                        ...profile,
                        pictureURL: this.authService.getProfilePictureURLByUserId(profile),
                    };
                }
                return profile;
            })
        );
    }
}
