import { UnauthorizedError } from "../util/Error";
import { fetchAndCheck } from "../util/Fetch";
import { IAuthorizationDescriptor, INormalizedRule, IAuthUserProfile } from "./AuthModel";

export interface IAuthInfo {
    userId: string;
    token: string;
    rules: INormalizedRule[];
}

export class AuthService {
    constructor(public apiURL: string) {}

    public check(token: string): Promise<IAuthInfo | false> {
        return fetchAndCheck(this.apiURL + "/auth/check", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
            credentials: "include",
        })
            .then((response) => response[0])
            .then(
                (json) => {
                    return {
                        userId: json.userId,
                        token: json.token,
                        rules: json.rules,
                    };
                },
                (err) => {
                    if (err instanceof UnauthorizedError) {
                        return false;
                    }
                    throw err;
                }
            );
    }

    public login(login: string, password: string): Promise<IAuthInfo | false> {
        return fetchAndCheck(this.apiURL + "/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ login, password }),
            credentials: "include",
        })
            .then((response) => response[0])
            .then(
                (json) => {
                    return {
                        userId: json.userId,
                        token: json.token,
                        rules: json.rules,
                    };
                },
                (err) => {
                    if (err instanceof UnauthorizedError) {
                        return false;
                    }
                    throw err;
                }
            );
    }

    public loginSharedKey(key: string): Promise<IAuthInfo | false> {
        return fetchAndCheck(this.apiURL + "/auth/loginSharedKey", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ key }),
            credentials: "include",
        })
            .then((response) => response[0])
            .then(
                (json) => {
                    return {
                        userId: json.userId,
                        token: json.token,
                        rules: json.rules,
                    };
                },
                (err) => {
                    if (err instanceof UnauthorizedError) {
                        return false;
                    }
                    throw err;
                }
            );
    }

    public logout(token: string): Promise<void> {
        return fetchAndCheck(this.apiURL + "/auth/logout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
            credentials: "include",
        }).then(() => undefined);
    }

    public getProfile(): Promise<IAuthUserProfile> {
        return fetchAndCheck(this.apiURL + "/auth/profile", {
            credentials: "include",
        })
            .then((response) => response[0])
            .then((json) => {
                return json as IAuthUserProfile;
            });
    }

    public getProfilePictureURL(userProfile?: IAuthUserProfile): string | undefined {
        return userProfile?.picture
            ? `${this.apiURL}/auth/profile/picture/${encodeURIComponent(userProfile.picture.id)}`
            : undefined;
    }

    public getAuthorizationDescriptors(): Promise<IAuthorizationDescriptor[]> {
        return fetchAndCheck(this.apiURL + "/auth/authorizationDescriptors", {
            credentials: "include",
        }).then((response) => {
            return response[0];
        });
    }

    public setUserPassword(currentPassword: string, newPassword: string, token?: string): Promise<boolean> {
        return fetchAndCheck(this.apiURL + "/auth/profile/password", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ currentPassword, newPassword }),
            credentials: "include",
        })
            .then((response) => response[0])
            .then(
                () => true,
                () => false
            );
    }

    public setUserProfilePicture(file?: File, token?: string): Promise<void> {
        return fetchAndCheck(
            this.apiURL + "/auth/profile/picture",
            file
                ? {
                      method: "POST",
                      headers: {
                          "Content-Type": file.type,
                          Authorization: `Bearer ${token}`,
                      },
                      body: file,
                      credentials: "include",
                  }
                : {
                      method: "DELETE",
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                      credentials: "include",
                  }
        ).then(() => undefined);
    }

    public getProfileByUserId(userId: string): Promise<IAuthUserProfile> {
        return fetchAndCheck(`${this.apiURL}/auth/profiles/${encodeURIComponent(userId)}`, {
            credentials: "include",
        })
            .then((response) => response[0])
            .then((json) => {
                return json as IAuthUserProfile;
            });
    }

    public getProfilePictureURLByUserId(userProfile?: IAuthUserProfile): string | undefined {
        return userProfile?.picture
            ? `${this.apiURL}/auth/profiles/${encodeURIComponent(userProfile.id)}/picture/${encodeURIComponent(
                  userProfile.picture.id
              )}`
            : undefined;
    }

    public async queryUserProfiles(query: string): Promise<IAuthUserProfile[]> {
        return fetchAndCheck(`${this.apiURL}/auth/profiles?query=${encodeURIComponent(query)}`, {
            credentials: "include",
        }).then((response) => response[0].result as IAuthUserProfile[]);
    }
}
