import { IAuthTokenStore } from "./AuthStore";

const AUTH_STORAGE_KEY = "app.authToken";

export class AuthTokenStoreLocalStorage implements IAuthTokenStore {
    public read() {
        return localStorage.getItem(AUTH_STORAGE_KEY) ?? undefined;
    }

    public write(token?: string) {
        if (token) {
            localStorage.setItem(AUTH_STORAGE_KEY, token);
        } else {
            localStorage.removeItem(AUTH_STORAGE_KEY);
        }
    }
}
