import { MessageFormatElement } from "react-intl";
import { observer } from "mobx-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { createIntl, createIntlCache, IntlProvider, IntlShape } from "react-intl";
import { AuthService } from "./auth/AuthService";
import { AuthStore } from "./auth/AuthStore";
import { Login } from "./auth/Login";
import { Operation } from "./ui/Operation";
import { locale } from "./util/i18n";
import { loadBootstrapMessages } from "./util/i18nBootstrapMessages";
import { loadMessages } from "./util/i18nMessages";
import { ThemeStore } from "./theme";
import { ErrorDialogComponent } from "./ui/errorDialog/ErrorDialogComponent";
import { AuthTokenStoreLocalStorage } from "./auth/AuthTokenStoreLocalStorage";
import { WithThemeProvider } from "./ui/WithThemeProvider";
import { AuthInjector } from "./auth/AuthInjector";
import { Accessibility } from "./Accessibility";

document.addEventListener(
    "touchmove",
    function (event) {
        (event = event as any)?.originalEvent || event;
        if ((event as any)?.scale > 1) {
            event.preventDefault();
        }
    },
    false
);

export const ThemeContext = React.createContext<ThemeStore>(new ThemeStore());

export const Root = observer(({ apiURL }: { apiURL: string }) => {
    const [authStore] = useState(() => new AuthStore(new AuthService(apiURL), new AuthTokenStoreLocalStorage()));
    const [checked, setChecked] = useState("unchecked");
    const [busy, setBusy] = useState(true);
    const [loginFailed, setLoginFailed] = useState(false);
    const [accessibility, setAccessibility] = useState(true);
    const [width, setWidth] = useState(0);

    const intlCache = createIntlCache();
    const bootstrapMessages = loadBootstrapMessages(locale);
    const intl = createIntl({ locale, messages: bootstrapMessages }, intlCache);

    React.useEffect(() => {
        function onResize() {
            const currentWidth = document.documentElement.clientWidth || window.outerWidth;
            if (currentWidth && width !== currentWidth) {
                setAccessibility(currentWidth > 743);
                setWidth(currentWidth);
            }
        }
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    useEffect(() => {
        if (checked === "unchecked") {
            setChecked("checking");
            new AuthInjector(apiURL).install().finally(() => {
                authStore.check().then((value) => {
                    setChecked("checked");
                    setBusy(false);
                });
            });
        }
    });

    function doLogin(login: string, password: string) {
        setBusy(true);
        Operation.create<void, boolean>(
            () => authStore.login(login, password),
            intl.formatMessage({ id: "operation.error.description.login.label" }),
            intl
        )
            .onError([{ type: "retry" }, { type: "abort" }])
            .build()
            .execute()
            .then((value) => {
                setLoginFailed(!value);
                setBusy(false);
            });
    }

    const renderAccessibility = React.useMemo(
        () => (
            <IntlProvider locale={locale} messages={bootstrapMessages}>
                <Accessibility />
            </IntlProvider>
        ),
        [bootstrapMessages]
    );

    if (checked !== "checked") {
        return null;
    } else if (authStore.loggedIn) {
        return (
            <WithThemeProvider>
                {!accessibility && renderAccessibility}
                <LazyMain auth={authStore} apiURL={apiURL} intl={intl} />
            </WithThemeProvider>
        );
    } else {
        return (
            <WithThemeProvider>
                <IntlProvider locale={locale} messages={bootstrapMessages}>
                    {!accessibility && renderAccessibility}
                    <Login
                        key="login"
                        onLogin={(login, password) => doLogin(login, password)}
                        busy={busy}
                        error={loginFailed}
                    />
                </IntlProvider>
            </WithThemeProvider>
        );
    }
});

type MainModule = typeof import("./app/Main");

export function LazyMain({ auth, apiURL, intl }: { auth: AuthStore; apiURL: string; intl: IntlShape }) {
    const [module, setModule] = useState<MainModule>();
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]>>();
    useEffect(() => {
        Operation.create<void, MainModule>(
            async () => {
                const result = await Promise.all([
                    loadMessages(locale).then((m) => setMessages(m)),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    import(/* webpackChunkName: "main" */ "./app/Main"),
                ]);
                return result[1];
            },
            intl.formatMessage({ id: "operation.error.description.loadApplication.label" }),
            intl
        )
            .onError([{ type: "retry" }])
            .build()
            .execute()
            .then((m) => {
                setModule(m);
            });
    }, []);

    return messages ? (
        <IntlProvider locale={locale} messages={messages}>
            <ErrorDialogComponent />
            {module && <module.Main auth={auth} apiURL={apiURL} />}
        </IntlProvider>
    ) : null;
}
