import * as React from "react";
import { Dialog, DialogFooter, DialogType, IDialogContentProps, IModalProps, PrimaryButton } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { observer } from "mobx-react";
import { IErrorDialogProps } from "./ErrorDialogCtrl";

interface IErrorDialogExtendedProps extends IErrorDialogProps {
    onClick: (reason: string) => void;
    visible: boolean;
}

export const ErrorDialog = observer(({ title, actions, message, onClick, visible }: IErrorDialogExtendedProps) => {
    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");

    const modalProps: IModalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            isDarkOverlay: false,
            styles: { layer: { zIndex: 1000002 } },
        }),
        [labelId, subTextId]
    );

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title,
        subText: message,
    };

    return (
        <div>
            <Dialog
                hidden={!visible}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={280}
                maxWidth={"80%"}
            >
                <DialogFooter>
                    {actions.map((v) => {
                        return (
                            <PrimaryButton key={v.name} onClick={() => onClick(v.name)}>
                                {v.label}
                            </PrimaryButton>
                        );
                    })}
                </DialogFooter>
            </Dialog>
        </div>
    );
});
