import { IAction } from "../Actions";
import { makeObservable, observable } from "mobx";

export interface IErrorDialogProps {
    title: string;
    message: string;
    actions: IAction[];
}

export class ErrorDialogCtrl {
    @observable visible = false;
    @observable data: IErrorDialogProps = {
        actions: [],
        message: "",
        title: "",
    };

    private reason = "";
    private interval?: ReturnType<typeof setInterval>;

    constructor() {
        makeObservable(this);
    }

    public onClick = (reason: string): void => {
        this.reason = reason;
    };

    public showErrorDialog(title: string, message: string, actions: IAction[]): Promise<string> {
        if (this.visible) {
            return Promise.reject(new Error("Can't show error dialog when an error dialog is already displayed."));
        }
        this.visible = true;
        this.data = {
            title,
            message,
            actions,
        };
        return new Promise<string>((resolve) => {
            this.interval = setInterval(() => {
                if (this.reason !== "") {
                    if (this.interval) clearInterval(this.interval);
                    this.visible = false;
                    resolve(this.reason);
                    this.reason = "";
                }
            }, 100);
        });
    }
}
