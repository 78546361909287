import * as React from "react";
import { useIntl } from "react-intl";
import {
    Depths,
    Dialog,
    DialogFooter,
    ITextField,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
    Theme,
} from "@fluentui/react";
import { Logo } from "../ui/common/Logo";
import { ThemeContext } from "../Root";

const loginClassNames = (theme: Theme) =>
    mergeStyleSets({
        logoWrapper: {
            width: "100%",
            textAlign: "left",
        },
        dialogFooter: {
            marginTop: 30,
            marginBottom: 10,
        },
    });

export function Login(props: { onLogin: (login: string, password: string) => void; error?: boolean; busy?: boolean }) {
    const [login, setLogin] = React.useState("");
    const [password, setPassword] = React.useState("");
    const loginRef = React.useRef<ITextField>(null);

    const intl = useIntl();
    const { theme } = React.useContext(ThemeContext);

    React.useEffect(() => {
        loginRef.current?.focus();
    }, [loginRef.current]);

    function handleEnter(e: React.KeyboardEvent) {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            doLogin();
        }
    }

    function doLogin() {
        props.onLogin(login, password);
    }

    const classNames = loginClassNames(theme);

    return (
        <Dialog
            hidden={false}
            title={
                <div className={classNames.logoWrapper}>
                    <Logo />
                </div>
            }
            minWidth={380}
            maxWidth={"80%"}
            dialogContentProps={{
                titleProps: {
                    "aria-label": intl.formatMessage({ id: "login.title" }),
                },
                styles: {
                    title: {
                        padding: "16px 46px 40px",
                    },
                },
            }}
            modalProps={{
                isDarkOverlay: false,
                styles: {
                    main: {
                        boxShadow: `${Depths.depth16}`,
                        padding: "30px 5px 20px 5px",
                        textAlign: "left",
                    },
                },
            }}
        >
            <Stack tokens={{ childrenGap: 20 }}>
                {props.error && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {intl.formatMessage({ id: "login.invalid-credentials" })}
                    </MessageBar>
                )}
                <TextField
                    type="email"
                    ariaLabel={intl.formatMessage({ id: "login.email" })}
                    placeholder={intl.formatMessage({ id: "login.email" })}
                    underlined
                    value={login}
                    onChange={(e, value) => setLogin(value ?? "")}
                    onKeyDown={(e) => handleEnter(e)}
                    componentRef={loginRef}
                />
                <TextField
                    type="password"
                    ariaLabel={intl.formatMessage({ id: "login.password" })}
                    placeholder={intl.formatMessage({ id: "login.password" })}
                    underlined
                    value={password}
                    onChange={(e, value) => setPassword(value ?? "")}
                    onKeyDown={(e) => handleEnter(e)}
                />
            </Stack>
            <DialogFooter className={classNames.dialogFooter}>
                <PrimaryButton
                    text={intl.formatMessage({ id: "login.login" })}
                    onClick={(e) => {
                        e.preventDefault();
                        doLogin();
                    }}
                    allowDisabledFocus
                    disabled={props.busy}
                />
            </DialogFooter>
        </Dialog>
    );
}
