import { MessageFormatElement } from "react-intl";

export enum Language {
    EN = "en",
    DE = "de",
}

export async function loadMessages(
    supportedLocale: string
): Promise<Record<string, string> | Record<string, MessageFormatElement[]>> {
    switch (supportedLocale) {
        case Language.DE:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (await import(/* webpackChunkName: "localization-de" */ "../localizations/messages-de")).messages;
        case Language.EN:
        default:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (await import(/* webpackChunkName: "localization-en" */ "../localizations/messages-en")).messages;
    }
}
