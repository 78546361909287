import { Language } from "./i18nMessages";
import { ILocalized, ITypeUI } from "../types/TypesModel";
import { IntlShape } from "react-intl";

export const locale: Language = (() => {
    const storedLoc = getStoredLocale();
    if (storedLoc !== undefined) {
        return mapSupportedLocale(storedLoc);
    }
    return mapSupportedLocale(navigator.language);
})();

function getLocaleFallback(l: Language) {
    switch (l) {
        case Language.DE:
            return [Language.DE, Language.EN];
        case Language.EN:
            return [Language.EN, Language.DE];
    }
}

export function localizeLocalizable<T>(localizable: ILocalized<T>, specificLocale: Language = Language.DE): T {
    const fallback = getLocaleFallback(specificLocale ?? locale);
    for (const l of fallback) {
        if (localizable[l]) {
            return localizable[l];
        }
    }
    // pick any if there is no fallback for the UI locale
    for (const l of Object.getOwnPropertyNames(localizable)) {
        return localizable[l];
    }
    throw new Error(`Localization failed: ${JSON.stringify(fallback)} ${JSON.stringify(localizable)}`);
}

export function getStoredLocale(): string | undefined {
    const storedLoc = localStorage.getItem("app.locale");
    if (storedLoc !== null) {
        const c = mapSupportedLocale(storedLoc);
        if (c !== storedLoc) {
            localStorage.setItem("app.locale", c);
        }
        return c;
    }
    return undefined;
}

export function setStoredLocale(loc: string | undefined) {
    if (loc === undefined) {
        localStorage.removeItem("app.locale");
    } else {
        const supLocale = mapSupportedLocale(loc);
        localStorage.setItem("app.locale", supLocale);
    }
}

// see load messages
function mapSupportedLocale(loc: string): Language {
    const m = /^([a-z]+).*$/.exec(loc);
    const v = m !== null ? m[1] : "";
    switch (v) {
        // case Language.EN:
        case Language.DE:
            return v;
        default:
            return Language.DE;
    }
}

export function inaccessibleUserIntl(intl: IntlShape): string {
    return intl.formatMessage(
        { id: "common.inaccessible" },
        {
            objectType: intl.formatMessage({
                id: "common.inaccessible.objectType.user",
            }),
        }
    );
}

export function inaccessibleTypeIntl(intl: IntlShape, type: string): string {
    return intl.formatMessage(
        { id: "common.inaccessible.typeElement" },
        {
            objectType: intl.formatMessage({
                id: "common.inaccessible.objectType.type",
            }),
            objectName: type,
        }
    );
}

export function inaccessibleEntityIntl(intl: IntlShape, typeUI: ITypeUI): string {
    return intl.formatMessage(
        { id: "common.inaccessible.typeElement" },
        {
            objectType: intl.formatMessage({
                id: "common.inaccessible.objectType.entity",
            }),
            objectName: localizeLocalizable(typeUI.label),
        }
    );
}

export function inaccessibleLocaleIntl(intl: IntlShape, locale: string): string {
    return intl.formatMessage(
        { id: "common.inaccessible.typeElement" },
        {
            objectType: intl.formatMessage({
                id: "common.inaccessible.objectType.locale",
            }),
            objectName: locale,
        }
    );
}
