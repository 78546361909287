export abstract class StructuredError extends Error {
    abstract type: string;
    resource?: string;
}

export class InputOutputError extends StructuredError {
    type = "InputOutput";
}

export class NotFoundError extends StructuredError {
    type = "NotFound";
}

export class AlreadyExistsError extends StructuredError {
    type = "AlreadyExists";
}

export class ConcurrentModificationError extends StructuredError {
    type = "ConcurrentModification";
}

export class ValidationError extends StructuredError {
    type = "Validation";
}

export class RequestedRangeNotSatisfiable extends StructuredError {
    type = "RequestedRangeNotSatisfiable";
}

export class UnauthorizedError extends StructuredError {
    type = "Unauthorized";
}

export class PreconditionRequiredError extends StructuredError {
    type = "PreconditionRequired";
}

export class InterceptorRejectionError extends StructuredError {
    type = "InterceptorRejects";
}

export class UnsupportedMediaTypeError extends StructuredError {
    type = "UnsupportedMediaType";
}

export class BadRequestError extends StructuredError {
    type = "BadRequest";
}

export class PayloadTooLargeError extends StructuredError {
    type = "PayloadTooLarge";
}

interface IStructuredErrorJSON {
    type: string;
    message?: string;
    resource?: string;
}

export function toJSON(error: StructuredError): { error: IStructuredErrorJSON } {
    const json: IStructuredErrorJSON = {
        type: error.type,
    };
    if (error.message) {
        json.message = error.message;
    }
    if (error.resource) {
        json.resource = error.resource;
    }
    return {
        error: json,
    };
}

export function fromJSON(json: { error?: IStructuredErrorJSON }): Error | null {
    if (typeof json === "object" && typeof json.error === "object") {
        let error: StructuredError;
        switch (json.error.type) {
            case "InputOutput":
                error = new InputOutputError(json.error.message);
                break;
            case "NotFound":
                error = new NotFoundError(json.error.message);
                break;
            case "AlreadyExists":
                error = new AlreadyExistsError(json.error.message);
                break;
            case "ConcurrentModification":
                error = new ConcurrentModificationError(json.error.message);
                break;
            case "RequestedRangeNotSatisfiable":
                error = new RequestedRangeNotSatisfiable(json.error.message);
                break;
            case "Validation":
                error = new ValidationError(json.error.message);
                break;
            case "Unauthorized":
                error = new UnauthorizedError(json.error.message);
                break;
            case "PreconditionRequired":
                error = new PreconditionRequiredError(json.error.message);
                break;
            case "InterceptorRejects":
                error = new InterceptorRejectionError(json.error.message);
                break;
            case "UnsupportedMediaType":
                error = new UnsupportedMediaTypeError(json.error.message);
                break;
            case "BadRequest":
                error = new BadRequestError(json.error.message);
                break;
            case "PayloadTooLarge":
                error = new PayloadTooLargeError(json.error.message);
                break;
            default:
                if (!json.error.message) {
                    return null;
                }
                return new Error(json.error.message);
        }
        if (json.error.resource) {
            error.resource = json.error.resource;
        }
        return error;
    }
    return null;
}
