import * as React from "react";
import { useIntl } from "react-intl";
import { Depths, Dialog, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { Logo } from "./ui/common/Logo";

const loginClassNames = mergeStyleSets({
    logoWrapper: {
        width: "100%",
        textAlign: "left",
    },
    dialogFooter: {
        marginTop: 30,
        marginBottom: 10,
    },
});

export function Accessibility(): JSX.Element {
    const intl = useIntl();
    const classNames = loginClassNames;

    return (
        <Dialog
            hidden={false}
            title={
                <div className={classNames.logoWrapper}>
                    <Logo />
                </div>
            }
            minWidth={380}
            maxWidth={"80%"}
            dialogContentProps={{
                styles: {
                    title: {
                        padding: "16px 46px 40px",
                    },
                },
            }}
            styles={{
                root: {
                    "& .ms-Overlay": {
                        backgroundColor: "white",
                    },
                },
            }}
            modalProps={{
                isDarkOverlay: false,
                styles: {
                    main: {
                        boxShadow: `${Depths.depth16}`,
                        padding: "30px 5px 20px 5px",
                        textAlign: "left",
                    },
                },
            }}
        >
            <Stack tokens={{ childrenGap: 20 }}>
                <Text variant="mediumPlus" block>
                    {intl.formatMessage({ id: "app.accessibility" })}
                </Text>
            </Stack>
        </Dialog>
    );
}
