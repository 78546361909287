import { fromJSON, InputOutputError } from "./Error";

export function fetchAndCheck(url: string, init?: RequestInit): Promise<[any, Response]> {
    return fetch(url, init).then(
        async (response) => {
            if (!response.ok) {
                let error: Error | null = null;
                try {
                    const json = await response.json();
                    error = fromJSON(json);
                } catch (e) {
                    // ignore
                }
                if (error) {
                    throw error;
                }
                throw new Error("Request for " + url + " failed: " + response.status + " " + response.statusText);
            }
            if (response.status === 204) {
                return [undefined, response];
            }
            return response.json().then((json) => {
                return [json, response];
            });
        },
        (error) => {
            throw new InputOutputError(error ? error.toString() : undefined); // Network connectivity issue
        }
    );
}
