export class AuthInjector {
    constructor(private apiUrl: string) {}

    async install(): Promise<void> {
        const reg = await navigator.serviceWorker.register(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            /* webpackChunkName: "serviceWorker" */ new URL("./AuthInjectorServiceWorker.js", import.meta.url)
        );
        await reg.update();
        await navigator.serviceWorker.ready;
        navigator.serviceWorker.controller?.postMessage({
            command: "setApiUrl",
            apiUrl: this.apiUrl,
        });
    }
}
