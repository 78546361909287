import { IAction } from "./Actions";
import { BusyService } from "./BusyIndicator";
import { ErrorDialogCtrl } from "./errorDialog/ErrorDialogCtrl";
import { IntlShape } from "react-intl";
import { AlreadyExistsError, NotFoundError, ConcurrentModificationError, UnauthorizedError } from "../util/Error";

export type ErrorHandler = IRetryErrorHandler | IAbortErrorHandler | ICancelErrorHandler | ICustomErrorHandler;

export type ErrorHandlerOutcome = "retry" | "abort" | "cancel";

export interface IRetryErrorHandler {
    type: "retry";
    label?: string;
}

export interface IAbortErrorHandler {
    type: "abort";
    label?: string;
}

export interface ICancelErrorHandler {
    type: "cancel";
    label?: string;
}

export interface ICustomErrorHandler {
    type: "custom";
    label: string;
    customFn: () => Promise<ErrorHandlerOutcome>;
}

interface IOperationStep {
    stepFn: (i: any) => Promise<any>;
    description: string;
    errors: {
        handlers: ErrorHandler[];
        description?: string;
        errorClass?: new () => any;
    }[];
}

export class OperationBuilder<I0, I, O> {
    private steps: IOperationStep[];
    private currentStep: IOperationStep;
    public readonly intl: IntlShape;

    public constructor(
        stepFn: (input: I) => Promise<O>,
        description: string,
        intl: IntlShape,
        steps?: IOperationStep[]
    ) {
        this.steps = steps ?? [];
        this.currentStep = {
            stepFn,
            description,
            errors: [],
        };
        this.intl = intl;
    }

    public onError<E extends Error>(handlers: ErrorHandler[], description?: string, errorClass?: new () => E): this {
        this.currentStep.errors.push({
            handlers,
            description,
            errorClass,
        });
        return this;
    }

    public step<I2, O2>(stepFn: (input: I2) => Promise<O2>, description: string): OperationBuilder<I0, I2, O2> {
        return new OperationBuilder<I0, I2, O2>(stepFn, description, this.intl, [...this.steps, this.currentStep]);
    }

    public build(): Operation<I0, O> {
        return new Operation<I0, O>([...this.steps, this.currentStep], this.intl);
    }
}

export class Operation<I, O> {
    public static errorDialogCtrl: ErrorDialogCtrl = new ErrorDialogCtrl();

    static create<I, O>(
        stepFn: (input: I) => Promise<O>,
        description: string,
        intl: IntlShape
    ): OperationBuilder<I, I, O> {
        return new OperationBuilder<I, I, O>(stepFn, description, intl);
    }

    static createDefaultAuthenticated<I, O>(
        stepFn: (input: I) => Promise<O>,
        description: string,
        intl: IntlShape
    ): OperationBuilder<I, I, O> {
        return Operation.addDefaultErrorHandlers(Operation.create(stepFn, description, intl));
    }

    static createDefaultLoad<O>(
        loadFn: () => Promise<O>,
        entity: string,
        intl: IntlShape,
        customizer?: (builder: OperationBuilder<void, void, O>) => void
    ) {
        const opBuilder = Operation.createDefaultAuthenticated<void, O>(
            loadFn,
            intl.formatMessage({ id: "operation.load.description" }, { entity }),
            intl
        );
        if (customizer) {
            customizer(opBuilder);
        }
        return opBuilder
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "abort",
                    },
                ],
                intl.formatMessage({ id: "operation.error.NotFound" }, { entity }),
                NotFoundError
            )
            .onError([
                {
                    type: "retry",
                },
                {
                    type: "abort",
                },
            ])
            .build();
    }

    static createDefaultSave<O>(
        saveFn: () => Promise<O>,
        entity: string,
        intl: IntlShape,
        customizer?: (builder: OperationBuilder<void, void, O>) => void
    ) {
        const opBuilder = Operation.createDefaultAuthenticated<void, O>(
            saveFn,
            intl.formatMessage({ id: "operation.save.description" }, { entity }),
            intl
        );
        if (customizer) {
            customizer(opBuilder);
        }
        return opBuilder
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "cancel",
                    },
                ],
                intl.formatMessage({ id: "operation.error.AlreadyExists" }, { entity }),
                AlreadyExistsError
            )
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "cancel",
                    },
                ],
                intl.formatMessage({ id: "operation.error.NotFound" }, { entity }),
                NotFoundError
            )
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "cancel",
                    },
                ],
                intl.formatMessage({ id: "operation.error.ConcurrentModification" }, { entity }),
                ConcurrentModificationError
            )
            .onError([
                {
                    type: "retry",
                },
                {
                    type: "cancel",
                },
            ])
            .build();
    }

    static createDefaultDelete<E, O>(
        readFn: () => Promise<E>,
        deleteFn: (entity: E) => Promise<O>,
        entity: string,
        intl: IntlShape,
        customizer?: (builder: OperationBuilder<void, void, O>) => void
    ) {
        return Operation.createDefaultAuthenticated<void, E>(
            readFn,
            intl.formatMessage({ id: "operation.delete.description.load" }, { entity }),
            intl
        )
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "abort",
                    },
                ],
                intl.formatMessage({ id: "operation.error.NotFound" }, { entity }),
                NotFoundError
            )
            .onError([
                {
                    type: "retry",
                },
                {
                    type: "abort",
                },
            ])
            .step(deleteFn, intl.formatMessage({ id: "operation.delete.description" }, { entity }))
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "abort",
                    },
                ],
                intl.formatMessage({ id: "operation.error.NotFound" }, { entity }),
                NotFoundError
            )
            .onError(
                [
                    {
                        type: "retry",
                    },
                    {
                        type: "abort",
                    },
                ],
                intl.formatMessage({ id: "operation.error.ConcurrentModification" }, { entity }),
                ConcurrentModificationError
            )
            .onError([
                {
                    type: "retry",
                },
                {
                    type: "abort",
                },
            ])
            .build();
    }

    private static addDefaultErrorHandlers<I0, I, O>(
        opBuilder: OperationBuilder<I0, I, O>
    ): OperationBuilder<I0, I, O> {
        return opBuilder.onError(
            [
                {
                    type: "custom",
                    label: opBuilder.intl.formatMessage({ id: "operation.outcome.reload" }),
                    customFn: async () => {
                        window.location.reload();
                        return "cancel";
                    },
                },
            ],
            opBuilder.intl.formatMessage({ id: "operation.error.Unauthorized" }),
            UnauthorizedError
        );
    }

    public constructor(private steps: IOperationStep[], private intl: IntlShape) {}

    public execute(input: I): Promise<O> {
        return BusyService.busy(
            (async () => {
                let result: any = input;
                for (let i = 0; i < this.steps.length; i++) {
                    const step = this.steps[i];
                    try {
                        result = await step.stepFn(result);
                    } catch (e: any) {
                        let error = step.errors.find((err) => err.errorClass && e instanceof err.errorClass);
                        if (!error) {
                            // look for default actions
                            error = step.errors.find((err) => !err.errorClass);
                        }
                        if (!error) {
                            // no action found, abort with current error
                            throw e;
                        }
                        const actions: IAction[] = error.handlers.map((eh, idx) => ({
                            name: idx.toString(),
                            label:
                                eh.label ??
                                this.intl.formatMessage({
                                    id: eh.type === "retry" ? "operation.outcome.retry" : "operation.outcome.cancel",
                                }),
                            icon: "",
                        }));
                        const title = this.intl.formatMessage(
                            { id: "operation.error.description" },
                            { description: step.description }
                        );
                        const sel = parseInt(
                            await Operation.errorDialogCtrl.showErrorDialog(
                                title,
                                error.description ??
                                    (e ? e.toString() : this.intl.formatMessage({ id: "operation.error.unknown" })),
                                actions
                            ),
                            10
                        );
                        const errorHandler = error.handlers[sel];
                        let outcome: ErrorHandlerOutcome;
                        if (errorHandler.type === "custom") {
                            outcome = await errorHandler.customFn();
                        } else {
                            outcome = errorHandler.type;
                        }
                        if (outcome === "abort") {
                            throw e;
                        } else if (outcome === "retry") {
                            i--;
                        } else if (outcome === "cancel") {
                            return new Canceller();
                        }
                    }
                }
                return result;
            })(),
            this.intl
        ).then((result) => {
            if (result instanceof Canceller) {
                return result.cancel();
            }
            return result;
        });
    }
}

class Canceller {
    cancel(): Promise<never> {
        return new Promise(() => undefined);
    }
}
