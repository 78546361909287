import * as React from "react";
import { observer } from "mobx-react";
import { ErrorDialog } from "./ErrorDialog";
import { Operation } from "../Operation";

export const ErrorDialogComponent = observer(() => {
    const errorDialogCtrl = Operation.errorDialogCtrl;
    return (
        <ErrorDialog
            onClick={errorDialogCtrl.onClick}
            actions={errorDialogCtrl.data.actions}
            message={errorDialogCtrl.data.message}
            title={errorDialogCtrl.data.title}
            visible={errorDialogCtrl.visible}
        />
    );
});
