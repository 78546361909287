import { projectBootstrapMessages } from "./project-bootstrap-messages-en";

export const bootstrapMessages: Record<string, string> = {
    "login.title": "Sign in",
    "login.email": "Email",
    "login.password": "Password",
    "login.login": "Sign in",
    "login.invalid-credentials": "Invalid email or password",

    "operation.load.description": "load {entity}",
    "operation.save.description": "save {entity}",
    "operation.delete.description": "delete {entity}",
    "operation.delete.description.load": "load {entity} for deletion",
    "operation.error.description": "Failed to {description}",
    "operation.error.description.login.label": "Login",
    "operation.error.description.loadApplication.label": "Load application",
    "operation.error.description.loadTypes.label": "Load types",
    "operation.error.unknown": "Unknown Error",
    "operation.error.NotFound": "The {entity} does not exist on the server. It may have been deleted by another user.",
    "operation.error.AlreadyExists":
        "The {entity} already exists on the server. It may have been created by another user.",
    "operation.error.ConcurrentModification":
        "The {entity} was modified on the server. It may have been edited by another user.",
    "operation.error.Unauthorized": "Your login session expired.",
    "operation.outcome.cancel": "Cancel",
    "operation.outcome.retry": "Retry",
    "operation.outcome.reload": "Reload",

    "app.status.loadingInProgress": "Loading...",
    ...projectBootstrapMessages,
};
